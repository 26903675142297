<template>
	<div class="about-detail">
		<h2 id="intro01">{{detail.name}}</h2>
		<div v-html="detail.content"></div>
       <!-- <p>公司由中国工程院卢秉恒院士担任首席科学家，国家级特聘专家、2020年广东省金属增材制造基础研究重大项目课题负责人之一张丽娟博士担任总工程师，核心研发力量来自国家增材制造创新中心增减材一体机项目组，通过佛山市科技创新团队项目引进落地产业化，是国内最早实现增减材一体化系统解决方案的制造厂商之一。</p>
       <p> 项目组专注于增减材复合制造装备、关键部件、工艺、软件等方面研发和制造，成功突破国外技术封锁，已成为国内增减材一体机销售最多的创新创业团队。</p>
       <div class="img-box"><img :src="require('../../assets/intro.jpg')" /></div>

       <div class="ultitle">发展历程</div>
       <ul>
       		<li>2019年07月——国家增材制造创新中心佛山分中心揭牌成立</li>
			<li>2019年12月——国家增材制造创新中心佛山分中心两条通用试验线建成</li>
			<li>2020年09月——广东增减材科技有限公司正式成立</li>
			<li>2020年10月——成为国内增减材一体机销售最多的创业团队，项目进入高速发展期，在佛山加快建设研发制造基地</li>
       </ul>

       <div class="ultitle">企业文化</div>

       <div class="ultitle c999">企业愿景</div>
		<p style="margin:0;">唯有创造改变世界，通过增减材一体化技术服务【产品制作】，为社会创造出更多与环境协调的丰富的器物</p>
		<div class="ultitle c999">企业使命</div>
		<p style="margin:0;">致力于提供卓越的增减材一体化解决方案，持续为客户创造最大价值</p>
		<div class="ultitle c999">企业价值观</div>
		<p style="margin:0;">诚实、创新、专业、高效、共享</p> -->

	</div>
</template>

<script>
import { ref,onMounted,watch,toRefs } from 'vue'
import {useRoute} from 'vue-router'

export default {
	name: 'ab',
	components:{},
	props:{
		list:{type:Array,default:[]}
	},
	setup(props){
		const route = useRoute();
		const detail = ref({content:'', name:''});
		const { list } = toRefs(props);
		onMounted(()=>{
			if(route.path==='/about/ab1'&&list.value.length>0){	
				detail.value = list.value[0]
			}
		})
		watch(list,()=>{
			detail.value = list.value[0]
		})
 		return {detail}
	}
}
</script>

<!-- <style scoped>
h2{font-size:22px; color:#111; font-weight: bold; padding:5px 0; text-align:center; margin:10px 0 10px 0; border-bottom:1px solid #eee;}
h3{font-size:19px; color:#333; font-weight: bold; padding:5px 0; margin:15px 0 0px 0; }
p{font-size:15px; line-height: 1.9; margin:10px 0px;}
.img-box{text-align:center;}
.img-box img{width: 740px;}

.ultitle{font-size:16px; color:#333; font-weight: bold; margin-top:15px;}
.ultitle.c999{color:#999; margin-top:5px;}
ul{margin-bottom:10px; list-style-type: disc; padding-left:20px; font-size:15px;}
ul li{line-height:1.9; color:#999;}

@media screen and (max-width:800px) {
.img-box img{width: 394px; margin-top:10px;}
}
</style> -->


<style scoped type="text/css">
	#intro01{font-size:22px; color:#111; font-weight: bold; padding:5px 0; text-align:center; margin:10px 0 20px 0; border-bottom:1px solid #eee;}
</style>
